.title{
    font-weight: 900;
    font-size: 0.9rem;
}
.spannn{
    margin-left: 1rem;
}
.parrafo{
    padding: 0.1rem 1rem 0.5rem 1rem;
    text-align: justify;
    text-decoration: none;
    font-size: 1rem;
} 