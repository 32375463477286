@import url('./font/stylesheet.css');

body {
  font-family: 'Red Hat Display Bold';
  color: #0d0c0e;
}

/* --- */
.blob {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* --- */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  /* visibility: hidden; */
  width: 165px;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  padding: 7px 2px;
  position: absolute;
  z-index: 1;
  top: 1px;
  right: 110%;
  opacity: 0;
  display: inline-block;
  animation-delay: 2s;
  animation-name: tooltip;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  margin-left: -0.2px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
@keyframes tooltip {
  0% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 1;
    display: inline-block;
  }
}
@media (max-width: 600px) {
  .tooltip {
    margin-top: 5.5rem;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.pac-item {
  padding: 5px;
}

.pac-container {
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
/**Itineraryes*/
.lupa_seller_view_itinerary_container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.lupa_seller_view_itinerary_container div{
  padding: 0px 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.lupa_seller_filtersitinerary_container{
  display:  flex;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.lupa_seller_filtersitinerary_secFilters{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.lupa_seller_filtersitinerary_theFilters{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.lupa_seller_filtersitinerary_secSearch{
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/**Itineraryes details*/
.lupa_seller_view_itidetails_container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.lupa_seller_view_itidetails_body{
  display: grid;
  font-size: 16px;
  grid-template-columns: 50% 50%;
  gap: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.lupa_seller_view_itidetails_item{
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.lupa_seller_view_itidetails_item .itidetails_item_title{
  font-weight: bold;
}